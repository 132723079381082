import {
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from 'gatsby';
import * as React from 'react';
import { Layout } from '../../components/GrowthTrack';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';

import useStyles from '../../components/GrowthTrack/step.styles';

const Step3 = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Layout>
      <Grid
        style={{ minHeight: 540 }}
        justify="center"
        alignContent="center"
        spacing={4}
        container
      >
        <Grid xs={12} md={4} item>
          <Card className={classes.stepCard} elevation={0}>
            <CardContent>
              <Typography
                align="center"
                variant="h6"
                color="primary"
                style={{ marginBottom: 32 }}
              >
                Join the Team
              </Typography>
              <Typography style={{ fontSize: '11pt' }}>
                Join us at Gonubie Farmers Hall for Step Three!
              </Typography>
              <br />
              <Typography style={{ fontSize: '11pt' }}>
                Connect to the opportunities available at Lifehouse Church to
                live out your purpose And serve others by using your God-given
                gifts.
              </Typography>
              <br />
              <Typography style={{ fontSize: '11pt' }}>
                To prepare for this in-person step, you can read about our{' '}
                <Typography
                  component="a"
                  href="/dream-team"
                  target="_blank"
                  style={{ fontSize: '11pt' }}
                >
                  Dream Team Areas of Service
                </Typography>
                .
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={4} style={{ position: 'relative' }} item>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: theme.spacing(7),
              left: 0,
              padding: theme.spacing(0.5),
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              boxShadow: theme.shadows[1],
              borderRadius: theme.spacing(0.5),
            }}
          >
            <ArrowRightIcon />
          </div>
          <Card className={classes.stepCard} elevation={0}>
            <CardContent>
              <Typography
                align="center"
                variant="h6"
                color="primary"
                style={{ marginBottom: 32 }}
              >
                Attend Step Three
              </Typography>
              <Typography>
                To attend Step Three in-person, register using{' '}
                <Typography
                  component="a"
                  href="https://lifehousechurchsa.churchcenter.com/people/forms/353611"
                >
                  this link
                </Typography>{' '}
                today.
              </Typography>
              <br />
              <Typography style={{ fontSize: '11pt' }}>
                If you have any questions, please contact us.
              </Typography>
              <br />
              <Typography style={{ fontWeight: 500, fontSize: '11pt' }}>
                Office:{' '}
                <span style={{ fontWeight: 400 }}>
                  admin@lifehousechurch.org.za
                </span>
              </Typography>
              <Typography style={{ fontWeight: 500, fontSize: '11pt' }}>
                Tel: <span style={{ fontWeight: 400 }}>076 990 0359</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Step3;
